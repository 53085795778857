import { Injectable } from '@angular/core';
import { ToastrManager } from 'ng6-toastr-notifications';

@Injectable()
export class ToastrService {

  constructor(private toastr: ToastrManager) { }

  showSuccess(msg:string) {
    this.toastr.successToastr(msg, 'Sucesso', {enableHTML: true});
  }

  showError(msg:string) {
    this.toastr.errorToastr(msg, 'Erro', {enableHTML: true});
  }

  showWarning(msg:string) {
    this.toastr.warningToastr(msg, 'Aviso', {enableHTML: true});
  }
}
