import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { LoaderService } from '../../services/loader/loader.service';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {

  exclude: Array<object> = [
    {
      url: 'product_category/category_children/',
      hasId: true,
    },
  ];

  constructor(
    private loaderService: LoaderService,
  ) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    const showLoader = request.body && typeof request.body['showSpinner'] !== 'undefined'
      ? request.body['showSpinner'] : true;

    this.loaderService.show();

    return next.handle(request).pipe(
      finalize(() => this.loaderService.hide()),
    );
 }

  private isValidRequestForInterceptor(requestUrl: string): boolean {
    const positionIndicator: string = 'api/';
    const position = requestUrl.indexOf(positionIndicator);

    if (position > 0) {
      const destination: string = requestUrl.substr(position + positionIndicator.length);

      for (let address of this.exclude) {
        let ref = address['url'];

        if (address['hasId']) {
          ref = address['url'].substring(0, address['url'].lastIndexOf('/') + address['url'].length);
        }

        if (new RegExp(ref).test(destination)) {
          return false;
        }
      }
    }

    return true;
  }
}
