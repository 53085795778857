import { Component, OnInit } from '@angular/core';
import {ApiService} from "../../services/api/api.service";
import {ActivatedRoute} from "@angular/router";
import {UserService} from "../../services/user/user.service";
import {ToastrService} from "../../services/toastr/toastr-service.service";
import {IOrderConfirmationData} from "../../models/OrderConfirmation";

declare var $: any;

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.css']
})
export class OrderConfirmationComponent implements OnInit {

  orderId;
  order;
  justify: string = '';
  certified = false;
  confirmation: IOrderConfirmationData = {
    orderCode: null,
    status: null,
    reason: ''
  };

  constructor(
    private apiService: ApiService,
    private activeRoute: ActivatedRoute,
    private userService: UserService,
    private toastr: ToastrService
  ) {
    this.orderId = this.activeRoute.params.subscribe(params => {
      this.orderId = params['code'];
      if (this.orderId) this.getOrder();
      this.confirmation.orderCode = this.orderId;
    });
  }

  ngOnInit() {
  }

  getOrder() {
    this.apiService.getOrderOne(this.orderId).subscribe(
      result => {
        if (result['error']) {
          this.toastr.showError(result['message']);
          return;
        }

        this.order = result['order'];
        this.getOrderConfirmation()
      },
      error => {
        console.error(error);
        this.toastr.showError("Ops! Ocorreu um erro ao carregar as informações do Pedido. Tente novamente.");
      }
    )
  }

  getOrderConfirmation() {
    this.apiService.getOrderConfirmation(this.order.id).subscribe(
      result => {
        if (result.error) {
          this.toastr.showError(result['message']);
          return;
        }

        if (result.data) {
          const data = result.data;
          this.confirmation.status = data.status;
          this.confirmation.reason = data.reason;
          this.justify = data.reason;

          if (data.status == 1) {
            this.certified = true;
          }
        }
      },
      error => {
        console.error(error);
        this.toastr.showError("Ops! Ocorreu um erro ao carregar as informações do Pedido. Tente novamente.");
      }
    );
  }

  /**
   * Altera entre as classes dos botões, fazendo a animação dos icones
   * @param id
   * @param className
   * @param add
   */
  toggleClass(id: string, className: string, add = false): void {
    if (this.certified) return;
    if (add) {
      document.getElementById(id).classList.add(className);
    } else {
      document.getElementById(id).classList.remove(className);
    }
  }

  sendJustify(status: number) {
    this.confirmation.status = status;

    if (status !== 2) {
      this.confirmation.reason = '';
    }

    this.apiService.addConfirmation(this.confirmation).subscribe(
      result => {
        result.error ? this.toastr.showError(result.message) : this.toastr.showSuccess(result.message);
        $('#send-justify-modal').modal('hide');
        this.getOrderConfirmation()
      },
      error => {
        console.error(error);
        this.toastr.showError("Ops! Ocorreu um erro ao fazer a confirmação do seu pedido. Tente novamente.");
      })
  }
}
