import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import moment from 'moment';
import { ApiService } from '../../services/api/api.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';
import { UserService } from '../../services/user/user.service';

declare const $: any;

@Component({
  selector: 'app-order-review',
  templateUrl: './order-review.component.html',
  styleUrls: ['./order-review.component.css']
})
export class OrderReviewComponent implements OnInit {

  orderCode;

  user: any = {};
  order = {};
  review;
  order_review_options: string[] = []
  show_review_order_as_options;
  showSelect: boolean = false;
  loadedData: boolean = false;

  constructor(
    private apiService: ApiService,
    private route: ActivatedRoute,
    private userService: UserService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit() {
    this.orderCode = this.route.snapshot.params['code'];

    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
      }

      this.getOrder();
      this.getUser();
    });
  }

  getOrder() {
    this.apiService.getOrderOne(this.orderCode).subscribe((res) => {
      if (!res['error']) {
        this.order = res['order'];

        this.order['products'].forEach(product => {
          product.image = product.images && product.images.length > 0 && typeof product.images[0] !== 'undefined' ?
            'https://p.biaction.com.br/' + this.user['client_id'] + '/products/300x300/' + product.images[0].filename :
            'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';

        });

        this.order['created_at'] = moment(this.order['created_at']).format('DD/MM/yyyy H:m');
      }

      this.getReview();
    });
  }

  getReview() {
    this.apiService.getOrderReview(this.order['id']).subscribe((res) => {
      if (!res['error']) {
        this.review = res['review'];
        if(this.review['products'].length > 0){
          this.showSelect = false;
        }
      }

      this.componentLoaded();
    });
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
        const client = this.user['client'];
        const options = client['order_review_options'];
        this.order_review_options = typeof options === 'string' ? JSON.parse(options) : options;
        this.show_review_order_as_options = client['show_review_order_as_options'];

        if (this.show_review_order_as_options == '1') {
          this.showSelect = true;
        }
      }
    });
  }


  saveReview() {
    const btn = $('#save');
    const products = [];

    this.order['products'].forEach((prod) => {
      const prodReview = $(`#product_${prod.id}_review`).val();
      const prodReviesOptions = $(`#product_${prod.id}_options`).val();
      let prodStars = 5;

      if ($(`#one_${prod.id}`).is(':checked')) {
        prodStars = 1;
      }

      if ($(`#two_${prod.id}`).is(':checked')) {
        prodStars = 2;
      }

      if ($(`#three_${prod.id}`).is(':checked')) {
        prodStars = 3;
      }

      if ($(`#four_${prod.id}`).is(':checked')) {
        prodStars = 4;
      }

      if ($(`#five_${prod.id}`).is(':checked')) {
        prodStars = 5;
      }

      products.push({
        id: prod.product_id,
        review: prodReview,
        reviewOptions: prodReviesOptions,
        stars: prodStars,
      });
    });

    this.order['review'] = $('#order-review').val();

    let msg = '';

    if (!this.order['review'] || this.order['review'] === '') {
      msg += 'O campo <b>Avaliação Geral do Pedido</b> deve ser preenchido! <br>';
    }

    if (msg === '') {
      btn.prop('disabled', true);
      btn.html('<i class="fa-solid fa-cog fa-spin"></i> Enviando...');

      this.apiService.saveOrderReview(this.order['id'], {
        user_id: this.user['id'],
        store_id: this.user['store']['id'],
        review: this.order['review'],
        products: products
      }).subscribe((res) => {
        if (!res['error']) {
          this.toastrService.showSuccess('A Avaliação foi enviada com sucesso!');
        } else {
          this.toastrService.showError('Ocorreu um erro desconhecido, tente novamente!');
        }

        btn.prop('disabled', false);
        btn.html('<i class="fa-solid fa-comment fa-star"></i> Enviar Avaliação');
        this.getReview();
      });
    } else {
      this.toastrService.showWarning(msg);
    }
  }

  rating(rated, itemId) {
    $(`#one_${itemId}`).prop('checked', true);
    $(`#two_${itemId}`).prop('checked', true);
    $(`#three_${itemId}`).prop('checked', true);
    $(`#four_${itemId}`).prop('checked', true);
    $(`#five_${itemId}`).prop('checked', true);

    if (rated === 1) {
      $(`#two_${itemId}`).prop('checked', false);
      $(`#three_${itemId}`).prop('checked', false);
      $(`#four_${itemId}`).prop('checked', false);
      $(`#five_${itemId}`).prop('checked', false);
    } else if (rated === 2) {
      $(`#three_${itemId}`).prop('checked', false);
      $(`#four_${itemId}`).prop('checked', false);
      $(`#five_${itemId}`).prop('checked', false);
    } else if (rated === 3) {
      $(`#four_${itemId}`).prop('checked', false);
      $(`#five_${itemId}`).prop('checked', false);
    } else if (rated === 4) {
      $(`#five_${itemId}`).prop('checked', false);
    }
  }

  componentLoaded() {
    this.loadedData = true;

    setTimeout(() => {
      if (this.review.products.length > 0) {
        this.review.products.forEach((prod) => {
          this.rating(prod.stars, prod.product_id);
          $(`#product_${prod.product_id}_review`).html(prod.review);
        });
      }

      if (this.review.review !== '') {
        $('#order-review').prop('disabled', true);
        $("#table-body :input").prop("disabled", true);
        $("#table-body textarea").prop("disabled", true);
        $("#save").prop("disabled", true);
      }
    });
  }

}
