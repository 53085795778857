import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import {User} from '../../models/User';
import { ApiService } from '../api/api.service';
import { AuthService } from '../auth/auth.service';
import {LocalStorageService} from '../local-storage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  user = {};

  constructor(
    private db: AngularFirestore,
    private authService: AuthService,
    private apiService: ApiService,
  ) { }

  validateUser(uid: string) {
    this.user = this.db.doc<User>(`users/${uid}`).valueChanges();
    return this.user;
  }

  getUserData() {
    return new Promise((resolve, reject) => {
      const userEmail = this.authService.getUserEmail();

      return this.apiService.getPdUserByEmail({
        email: userEmail,
      }).subscribe((res) => {
        if (!res['error']) {
          return resolve({
            error: false,
            user: res['user'],
          });
        }
      }, error => {
        localStorage.removeItem('authToken');
        localStorage.removeItem('expirationToken');
        localStorage.removeItem('user');
        window.location.href = '/home';
      });
    });
  }
}
