import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class MaintenanceGuard implements CanActivate {
  
  urlBase: string;
  
  constructor(
    private router: Router,
    private authService: AuthService,
  ) { 
    const url = this.router['location']._platformLocation.location.origin;
    // const url = 'https://bemfixa.sellentt.com.br/home';
    // const url = 'https://uzzyferramentas.sellentt.com.br/';
    // const url = 'https://suportescatarinense.sellentt.com.br/home';
    this.urlBase = url.split('://')[1].split('.')[0];
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot) {

    const isMaintenance = await this.authService.getEcommerceStatus(this.urlBase);
    
    if (isMaintenance) {
      this.router.navigate(['/maintenance']);
      return false;
    }

    return true;
  }
  
}
