import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/user/user.service';

import Swiper, { Navigation, Pagination } from 'swiper';
import * as numeral from 'numeral';
import { GoldApiService } from '../../services/gold-api/gold-api.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';
import { CartService } from '../../services/carts/cart.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { NgForm } from '@angular/forms';
import { FirebaseApp } from '@angular/fire';
import { SalePolicyService } from '../../services/sale-policy/sale-policy.service';
import {AuthService} from '../../services/auth/auth.service';

declare var $: any;

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.css'],
})
export class ProductDetailsComponent implements OnInit {
  userReviews = [];
  variations = [];
  product = {};
  products = [];
  custom_fields = [];
  custom_values = [];
  technical_sheet = [];
  additional_info = [];

  review;
  productId;
  user;
  ncm_list: any;
  isento_st = false;
  clientId;
  firebaseId;

  orderTypeId;

  loadedData: boolean = false;
  showGoldCotation: boolean = false;
  showGoldCotationTotal: boolean = false;

  cotation;
  cotationTotal;

  outOfStockNotifyCreated = false;

  isStockActive = false;
  removeTextIpiStPauta = false;
  showStock = false;

  productID;
  wishlists: any[] = [];
  newWishlistName: string = '';
  newFavorite: any[] = [];
  isLoggedIn: boolean;

  private sub: any;

  constructor(
    private firebase: FirebaseApp,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private userService: UserService,
    private goldApiService: GoldApiService,
    private router: Router,
    private toastrService: ToastrService,
    private authService: AuthService,
    private localStorageService: LocalStorageService,
    private salesPolicyService: SalePolicyService
  ) {
    this.clientId = this.localStorageService.get('pd_id');
    this.firebaseId = this.localStorageService.get('biaction_id');
  }

  ngOnInit() {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.sub = this.route.params.subscribe((params) => {
      const id = params['id'];

      const queryParams = this.route.snapshot.queryParams;

      if (id) {
        this.productId = id;
      }

      this.orderTypeId = queryParams['orderTypeId'];

      this.userService.getUserData().then((res) => {
        if (!res['error']) {
          this.user = res['user'];
          this.getPriceTableById();

          if (this.user['avatar'] && this.user['avatar'] !== '{}') {
            this.user['avatar'] = JSON.parse(this.user['avatar']);
          } else {
            this.user['avatar'] = {
              name: 'avatar',
              version: 1,
            };
          }
        }

      });
      this.getEcommerceConfigs();
      this.getPricingPolicy();
    });
  }

  getEcommerceConfigs() {
    this.salesPolicyService.getEcommercePolice(this.firebaseId).then((res) => {
      if (res) {
        this.removeTextIpiStPauta = res['remove_ipi_st_pauta'] ? res['remove_ipi_st_pauta'] : false;
        this.showStock = res && res['show_stock'] ? res['show_stock'] : false;
      }
    });

  }

  getPricingPolicy() {
    this.salesPolicyService.getPolices(this.firebaseId).then((res) => {
      if (res && typeof res['fields'] !== 'undefined') {
        if (
          typeof res['fields']['stock_ecommerce'] !== 'undefined' &&
          res['fields']['stock_ecommerce']
        ) {
          this.isStockActive = true;
        } else {
          this.isStockActive = false;
        }
      }
    });
  }

  getPriceTableById() {
    this.apiService.getPriceTablesAll().subscribe((res) => {
      if (!res['error']) {
        // console.log(this.user);
        const priceTables = res['price_tables'];

        const storePriceTable = priceTables.find(
          (priceTable) => priceTable.id === this.user.store.default_price_table
        );

        this.user.store.default_price_table_code = storePriceTable.code;

        this.getTaxRules();
        this.getProductCustomFields();
      }
    });
  }

  getTaxRules() {
    const store = this.user && this.user.store ? this.user.store : {};
    const tax_rules_id = store.tax_rules_id ? store.tax_rules_id : '';

    if (tax_rules_id !== '') {
      this.apiService.getTaxRules(tax_rules_id).subscribe((res) => {
        if (!res['error']) {
          const fiscalTaxRules = res['fiscalTaxRules'];
          if (fiscalTaxRules !== undefined) {
            this.isento_st = fiscalTaxRules.isento_st
              ? fiscalTaxRules.isento_st
              : false;
            this.ncm_list = fiscalTaxRules.ncm;
          }
        }
      });
    }
  }

  getProduct() {
    // this.firebase.functions().useFunctionsEmulator('http://localhost:5001');
    const productsRequest = this.firebase
      .functions()
      .httpsCallable('products-getCart');

    const data = {
      price_table_id: this.user.store.default_price_table_code,
      source: 'new-ecommerce',
      company: {
        id: this.firebaseId,
      },
      ids: [this.productId],
      order_type_id: this.orderTypeId,
    };

    productsRequest(data)
      .then((res) => {

        this.products = res['data']['products'];

        this.products.forEach((prod) => {
          prod['image'] = prod['photo_cover']
            ? 'https://p.biaction.com.br/' +
              this.user.client_id +
              '/products/300x300/' +
              prod['photo_cover']
            : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';

          prod.images = [];

          prod['price_no_discounts'] = prod['product_price_table'];
          prod['price_with_discounts'] = prod['product_price_table'] - (prod['product_price_table'] * prod['default_discount']) / 100;

          if (prod.images.length > 0) {
            prod.images.forEach((img) => {
              img.link =
                img && typeof img.filename !== 'undefined'
                  ? 'https://p.biaction.com.br/' +
                    this.clientId +
                    '/products/300x300/' +
                    img.filename
                  : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';
            });
          }

          const ipi = prod.ipi;
          if (
            prod.ncm !== '' &&
            this.ncm_list !== undefined &&
            Object.keys(this.ncm_list).length > 0
          ) {
            const ncm = this.ncm_list[prod.ncm];
            if (ncm !== undefined) {
              const state = this.user.store.address_state;
              const ipi_ncm = ncm.ipi;
              const isento_ipi = ncm.isento_ipi ? ncm.isento_ipi : false;
              if (!isento_ipi) {
                if (ipi_ncm && !isNaN(ipi_ncm)) {
                  prod['ipi'] = ipi_ncm;
                } else {
                  prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                }
              } else {
                prod['ipi'] = 0;
              }
              if (!this.isento_st) {
                if (prod.fromabroad) {
                  prod['st'] =
                    ncm.im !== undefined && ncm.im[state] !== undefined
                      ? ncm.im[state]
                      : 0;
                } else {
                  prod['st'] =
                    ncm.st !== undefined && ncm.st[state] !== undefined
                      ? ncm.st[state]
                      : 0;
                }
              } else {
                prod['st'] = 0;
              }
              prod['tax_discount'] =
                ncm.tax_discount !== undefined &&
                ncm.tax_discount[state] !== undefined
                  ? ncm.tax_discount[state]
                  : 0;
            } else {
              prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
              prod['st'] = 0;
              prod['tax_discount'] = 0;
            }
          } else {
            prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
            prod['st'] = 0;
            prod['tax_discount'] = 0;
          }

          prod.has_discount =
            prod.price_no_discounts > 0 &&
            prod.price_no_discounts > prod.price_with_discounts
              ? true
              : false;

          prod.price_no_discounts =
            prod.price_no_discounts && prod.price_no_discounts > 0
              ? numeral(prod.price_no_discounts).format('0,0.00')
              : numeral(0).format('0.0,00');

          prod.price_with_discounts =
            prod.price_with_discounts && prod.price_with_discounts > 0
              ? numeral(prod.price_with_discounts).format('0,0.00')
              : numeral(0).format('0.0,00');

          prod.total_discount_percentage = prod.has_discount
            ? numeral(
                ((prod.price_with_discounts - prod.price_no_discounts) /
                  prod.price_no_discounts) *
                  100
              ).format('0.0,00')
            : 0;

          prod['stock'] = prod['stock_place_order_type'] > 0 ? prod['stock_place_order_type'] : 0;

          if (prod['relations'] && prod['relations'].length > 0) {
            prod['relations'].forEach((related) => {
              related = related['product_related'];

              if (related && related !== null) {
                related.image =
                  related.images !== null &&
                  related.images &&
                  related.images.length > 0 &&
                  typeof related.images[0] !== 'undefined'
                    ? 'https://p.biaction.com.br/' +
                      this.clientId +
                      '/products/300x300/' +
                      related.images[0].filename
                    : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';

                related.has_discount =
                  related.price_no_discounts > 0 &&
                  related.price_no_discounts > related.price_with_discounts
                    ? true
                    : false;

                related.price_no_discounts =
                  related.price_no_discounts && related.price_no_discounts > 0
                    ? numeral(related.price_no_discounts).format('0.0,00')
                    : numeral(0).format('0.0,00');

                related.price_with_discounts =
                  related.price_with_discounts &&
                  related.price_with_discounts > 0
                    ? numeral(related.price_with_discounts).format('0.0,00')
                    : numeral(0).format('0.0,00');

                related.total_discount_percentage = related.has_discount
                  ? numeral(
                      ((related.price_with_discounts -
                        related.price_no_discounts) /
                        related.price_no_discounts) *
                        100
                    ).format('0.0,00')
                  : 0;
              }
            });
          }
        });

        this.product = this.products.find(
          (p) => String(p.id) === String(this.productId)
        );

        if (this.user['client']['show_users_products_reviews']) {
          this.getProductReviews();
        } else {
          this.componentLoaded();
        }

        setTimeout(() => {
          // this.initiateImagesZoom();

          if (this.user['client']['show_gold_to_reais_cotation']) {
            this.getCotationToGold();
            this.showGoldCotation = true;

            if (this.user['client']['show_gold_to_reais_cotation_total']) {
              this.showGoldCotationTotal = true;
            }
          }
        }, 500);
      })
      .catch((error) => {
        console.error(error);
      });

    /*this.apiService
      .getProductsEcommerce({
        user: this.user,
      })
      .subscribe((res) => {
        if (!res['error']) {
          console.log(res['products'])
        }
      });*/
  }

  getProductCustomFields() {
    this.apiService.getProductCustomFields().subscribe((res) => {
      if (res['error']) {
        console.log('Erro ao importar os campos personalizados do produto!');
      } else {
        this.custom_fields = res['productCustomFields'];

        if (this.custom_fields.length > 0) {
          this.setProductCustomFields();
        } else {
          this.getProduct();
        }
      }
    });
  }

  getProductReviews() {
    this.apiService.getProductReviews(this.productId).subscribe((res) => {
      if (!res['error']) {
        this.userReviews = res['userReviews'];
        this.review = {
          stars: res['stars'],
          total: res['total'],
          average: res['average'],
        };
      }

      this.componentLoaded();
    });
  }

  setProductCustomFields() {
    const that = this;

    this.apiService
      .getProductCustomValues('?product_id=' + this.productId)
      .subscribe((res) => {
        if (res['error']) {
          console.log(res['message']);
        } else {
          this.custom_values = res['productCustomValues'];

          this.technical_sheet = [];
          this.additional_info = [];

          if (this.custom_values && this.custom_values.length > 0) {
            for (let i = 0; i < this.custom_values.length; i++) {
              const custom_field_value = this.custom_values[i];
              const custom_field = this.custom_fields.find(
                (i) => i.id === custom_field_value.product_custom_field_id
              );

              if (custom_field) {
                if (custom_field.custom_field_type === 'technical_sheet') {
                  this.technical_sheet.push(
                    custom_field.name + '|' + custom_field_value.value
                  );
                } else {
                  this.additional_info.push(
                    custom_field.name + '|' + custom_field_value.value
                  );
                }
              }
            }
          }

          /* if (this.custom_values !== undefined) {
          this.custom_values.forEach(p => {
            if (p.custom_field.type === 'radio') {
              $(`#product_${this.productId}_custom_field_${p.custom_field.id}`).prop('checked', true);
            } else {
              $(`#product_${this.productId}_custom_field_${p.custom_field.id}`).val(p.value);
            }
          });
        } */
        }

        this.getProduct();
      });
  }

  getCotationToGold() {
    this.goldApiService.goldToDollar().subscribe((res) => {
      if (res && typeof res['price'] !== 'undefined') {
        this.goldApiService.dollarToBrl().subscribe((response) => {
          if (response[0] && response[0]['ask']) {
            const quantity = $('qtd').val() || 1;

            this.cotation = numeral(
              (res['price'] / 31) * response[0]['ask']
            ).format('0.0,00');
            this.cotationTotal = numeral(
              this.cotation * this.product['price_with_discounts'] * quantity
            ).format('0.0,00');
          }
        });
      }
    });
  }

  initiateImagesZoom() {
    $('.zoom').mousemove(function (event) {
      const zoomer = event.currentTarget;
      let offsetX;
      let offsetY;

      if (event.offsetX) {
        offsetX = event.offsetX;
      } else {
        offsetX = event.touches[0].pageX;
      }

      if (event.offsetY) {
        offsetY = event.offsetY;
      } else {
        offsetX = event.touches[0].pageX;
      }

      const x = (offsetX / zoomer.offsetWidth) * 100;
      const y = (offsetY / zoomer.offsetHeight) * 100;

      zoomer.style.backgroundPosition = (x - 9) + '% ' + (y - 9) + '%';
    });

    const swiper = new Swiper('.mySwiper', {
      spaceBetween: 12,
      freeMode: true,
      watchSlidesProgress: true,
      breakpoints: {
        1024: {
          slidesPerView: 6,
        },
        1: {
          slidesPerView: 3,
        },
      },
    });

    const swiper2 = new Swiper('.mySwiper2', {
      spaceBetween: 10,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      thumbs: {
        swiper: swiper,
      },
    });

    setTimeout(() => {
      $('.related-post-slider').slick({
        infinite: false,
        slidesToShow: 6,
        slidesToScroll: 1,
        dots: false,
        arrows: true,
        autoplay: false,
        spaceBetween: 5,
        autoplaySpeed: 1500,
        adaptiveHeight: true,
        prevArrow: '.slider-btn--prev',
        nextArrow: '.slider-btn--next',
        responsive: [
          {
            breakpoint: 1400,
            settings: {
              slidesToShow: 6,
            },
          },
          {
            breakpoint: 1200,
            settings: {
              slidesToShow: 4,
            },
          },
        ],
      });
    }, 1000);
  }

  componentLoaded() {
    this.loadedData = true;
  }

  insertCart(product) {
    const minimum = product.minimum ? product.minimum : 0;
    const multiple = product.multiple ? product.multiple : 0;
    const maximum = product.maximum ? product.maximum : 0;
    let msg = '';

    const qtd = $('#qtd-input').val() ? $('#qtd-input').val() : 1;

    if (minimum > 0 && qtd > 0) {
      if (qtd < minimum) {
        msg += 'A Quantidade mínima para esse produto é ' + minimum + '.<br>';
      }
    }

    if (maximum > 0 && qtd > 0) {
      if (qtd > maximum) {
        msg += 'A Quantidade máxima para esse produto é ' + maximum + '.<br>';
      }
    }

    if (multiple > 0 && qtd > 0) {
      const rest = (parseFloat((100 * qtd).toFixed(2)) % parseFloat((100 * multiple).toFixed(2))) / 100;
      if (rest !== 0) {
        msg += 'A Quantidade deve ser um valor múltiplo de ' + multiple + '.<br>';
      }
    }

    if (msg !== '') {
      this.toastrService.showWarning(msg);
    } else {
      this.router.navigate([`/precart/${product.id}`], {queryParams: {qtd: qtd}});
    }
  }

  handleOutOfStockNotification(form: NgForm) {
    let values = form.value;
    values['productId'] = this.product['id'];

    this.apiService.addOutOfStockNotification(values).subscribe(
      (result) => {
        if (result.error) {
          this.toastrService.showError(result.message);
          return;
        }

        this.outOfStockNotifyCreated = true;
      },
      (error) =>
        this.toastrService.showError(
          'Desculpe! Não foi possível salvar suas informações. Tente novamente.'
        )
    );
  }

  shareProduct(product) {
    const navigator = window.navigator as any;
    const url = this.router.url;
    const text = `${product.name} (${product.code})`;

    navigator.share({
      title: text,
      url: url,
    });
  }

  hasStock(item) {
    if (this.isStockActive) {
      if (item.stock > 0 && item.has_stock) {
        return true;
      }

      return false;
    }

    return true;
  }


   openWishlist(product) {

    this.productID = product.id;

    this.getWishlist();

    $(`#modal-wishlist-${product.id}`).modal('show');
  }


  getWishlist(){
    this.wishlists = [];
    this.apiService.getWishlists().subscribe((res) => {
      if (!res.error) {
        this.wishlists = res.wishlists;
        this.loadProductsInWishlist(this.productID)
      } else {
        console.error('Desculpe! Nenhuma lista de desejos encontrada', res.message);
      }
    }, (err) => {
      console.error('Desculpe! Nenhuma lista de desejos encontrada', err);
    });
  }

  loadProductsInWishlist(productId) {
    if (productId) {
      this.apiService.getProductsWishlistById(productId).subscribe((res) => {
        if (!res.error) {
          const wishlistIds = res.productsWishlist.map((item) => item.wishlist_id)
          wishlistIds.forEach((wishlistId) => {
            const checkbox = $(`#wishlist_${wishlistId}_${productId}`);
            if (checkbox.length) {
              checkbox.prop('checked', true);
            } else{
              checkbox.prop('checked', false);
            }
          });
        } else {
          console.error('Erro ao obter os produtos da lista de desejos:', res.message);
        }
      });
    } else {
      console.error('ID da lista de desejos não encontrado');
    }
  }


  createNewWishlist() {
    if (this.newWishlistName.trim() === '') {
      alert('O nome da lista é obrigatório.');
      return;
    }
    this.apiService.createWishlist(this.newWishlistName).subscribe((res) => {
      if (!res.error) {

        this.apiService.getWishlists().subscribe((res) => {
          if (!res.error) {
            this.wishlists = res.wishlists;
          }
          this.toastrService.showSuccess('Lista Criada com sucesso!');
        });

        this.newWishlistName = '';
      } else {
        this.toastrService.showWarning('Desculpe! Lista ja existe');
      }
    }, (err) => {
      console.error('Desculpe! Lista ja existe', err);
      this.toastrService.showWarning('Desculpe! Lista ja existe');
    });
  }



  onChangeFavorite(product: any, wishlistId: string) {
    const checkbox = $(`#wishlist_${wishlistId}_${product.id}`);
    if (checkbox.is(':checked')) {
      this.addProductList(wishlistId, product);
    } else {
      this.delProductList(wishlistId, product.id);
    }
  }


  addProductList(wishlistId: string, product: any) {
    this.apiService.addProduct(wishlistId, product).subscribe((res) => {
      if (!res.error) {
        this.newFavorite = res.selected;
        this.toastrService.showSuccess('Produto adicionado com sucesso!');
      } else {
        this.toastrService.showError('Erro ao selecionar o produto. Por favor, tente novamente');
      }
    }, (err) => {
      console.error('Erro ao selecionar o item. Por favor, tente novamente:', err);
      this.toastrService.showError('Erro ao selecionar o produto. Por favor, tente novamente');
    });
  }



  delProductList(wishlistId: string, productId: string) {

    this.apiService.delProduct(wishlistId, productId).subscribe((res) => {
        if (!res.error) {
            this.newFavorite = res.selected;
            this.toastrService.showSuccess('Produto deletado com sucesso!');
        } else {
          this.toastrService.showError('Erro ao excluir o item. Por favor, tente novamente');
        }
    }, (err) => {
        console.error('Erro ao excluir o produto. Por favor, tente novamente:', err);
        this.toastrService.showError('Erro ao excluir o produto. Por favor, tente novamente');
    });
  }
}
