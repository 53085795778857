import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/user/user.service';

declare var $: any;

@Component({
  selector: 'app-stores',
  templateUrl: './stores.component.html',
  styleUrls: ['./stores.component.css']
})
export class StoresComponent implements OnInit {

  loadedData:boolean = false;

  user;
  stores = [];

  constructor(
    private apiService: ApiService,
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
      }

      this.getUserStores();
    });
  }


  getUserStores() {
    this.apiService.getUserStores(this.user.id).subscribe((res) => {
      if (!res['error']) {
        this.stores = res['stores'];
      }

      this.loadedData = true;
    });
  }

}
