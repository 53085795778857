export const environment = {

  production: true,
  envName: 'prod',
  firebase: {
    apiKey: "AIzaSyAQLF7aqe70eqIZ317H56LFp2GI7vQzhno",
    authDomain: "bi-action.firebaseapp.com ",
    databaseURL: "https://bi-action.firebaseio.com",
    projectId: "bi-action",
    storageBucket: "bi-action.appspot.com",
    messagingSenderId: "246995100032",
    storageSecondBucket: "bi-action-dev",
    storageBaseUrl: `https://storage.googleapis.com/bi-action-dev`
  }

};
export const varGlobals = {
  // envnameComp : 'Smarter',
  // altName: 'Smarter',
  // imgLogin: 'https://p.biaction.com.br/gettsmarter/logo-smarter.png', 
  // imgLogo: 'https://p.biaction.com.br/gettsmarter/logo-smarter.png',
  // imgIcon: 'https://p.biaction.com.br/gettsmarter/smarter-icon.png'

  envnameComp : 'Biaction',
  altName: 'BI-Action',
  imgLogin: 'https://p.biaction.com.br/biaction/bi-login.png', 
  imgLogo: 'https://p.biaction.com.br/biaction/bi-menu.png',
  imgIcon: 'https://p.biaction.com.br/biaction/bi-icon.png'
};