import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

const httpOptions = {
  headers: new HttpHeaders({
    'x-access-token': 'goldapi-1xgd9ukkzhz1ap-io',
    'Content-Type': 'application/json',
  })
};

@Injectable({
  providedIn: 'root'
})
export class GoldApiService {
  private baseUrl = '';

  constructor(
    private http: HttpClient,
  ) {
    this.baseUrl = `https://www.goldapi.io/api/XAU/USD`;
   }

  goldToDollar() {
    return this.http.get(this.baseUrl, httpOptions);
  }

  dollarToBrl() {
    return this.http.get('https://economia.awesomeapi.com.br/USD-BRL');
  }
}
