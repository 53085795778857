import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { IOrderConfirmationData } from "../../models/OrderConfirmation";

interface IResponseData {
  error: boolean;
  message: string;
  data?: any
}
interface WishlistResponse {
  error: boolean;
  wishlists: any[];
  message: string;
}

interface UserGroup{
  error: boolean;
  userGroup: any[];
  message: string;
}

interface DiscountCouponsResponse {
  error: boolean;
  discountCoupons: any[];
  message: string;
}

interface catalog{
  error: boolean;
  catalog: any[];
  message: string;
}

interface wishlistsProductsResponse{
  error: boolean;
  wishlists: any[];
  message: string;
}



interface IAddNoStockNotificationData {
  product_id: number;
  userName: string;
  userEmail: string;
}

interface IPasswordResetData {
  email: string;
  password: string;
  token: string;
  clientId: number;
}

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
    Authorization: 'user_token'
  })
};

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl = '';
  private userToken: string | null = '';

  private storesUrl = '/stores';
  private withoutAuthFunctionsUrl = '/without_auth_functions';
  private ecommerceAuthUrl = '/ecommerceAuth';
  private productsUrl = '/products';
  private pdUsersUrl = '/users';
  private productCategoryUrl = '/product_category';
  private usersUrl = '/users';
  private paymentMethodsUrl = '/payment_methods';
  private paymentTermsUrl = '/payment_terms';
  private orderTypeUrl = '/order_types';
  private userGroupUrl = '/user_group';
  private orderReceiveConfirmationUrl = '/orders_receive_confirmation';
  private orderStatusUrl = '/orders_status';
  private productCustomFieldsUrl = '/product_custom_fields';
  private productCustomValuesUrl = '/product_custom_values';
  private pagarmeB2bUrl = '/pagarme_b2b';
  private branchUrl = '/enterprises';
  private likedProductsUrl = '/liked_products';
  private orderReviewUrl = '/orders_reviews';
  private gradeUrl = '/grade';
  private taxRulesUrl = '/tax_rules';
  private priceTablesUrl = '/price_tables';
  private productImagesUrl = '/product_images';
  private wishlistsUrl = '/wishlists';
  private wishlistsProductsUrl = '/wishlists_products';
  private DiscountCouponsUrl = '/discount_coupons';
  private catalogsUrl = '/catalogs';

  constructor(
    private http: HttpClient,
  ) {
    //this.baseUrl = `http://localhost:5001/bi-action/us-central1/api`;
    this.baseUrl = `https://us-central1-bi-action.cloudfunctions.net/api`;

    if (localStorage.getItem('authToken')) {
      this.userToken = localStorage.getItem('authToken') + 'ECOMMERCESELLENTT';
    }
  }

  getB2bConfigs(id: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.withoutAuthFunctionsUrl + '/getB2bConfigs' + id, httpOptions);
  }

  getB2bConfigsByUrl(params: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.withoutAuthFunctionsUrl + '/getB2bConfigsByUrl' + params, httpOptions);
  }

  createStore(id: string, data) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.withoutAuthFunctionsUrl + '/createStore' + id, data, httpOptions);
  }

  getStoreCustomFields(id: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.withoutAuthFunctionsUrl + '/getStoreCustomFields' + id, httpOptions);
  }

  getStoresByCompany(urlParams: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.storesUrl + '/getStoresByCompany' + urlParams, httpOptions);
  }

  // User login methods
  signInEcommerce(id: string, data) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.ecommerceAuthUrl + '/loginEcommerce' + id, data, httpOptions);
  }

  signOutEcommerce(id: string, data) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.withoutAuthFunctionsUrl + '/logoutEcommerce' + id, data, httpOptions);
  }

  /**
   * Função auxiliar para enviar o email de redefinição de senha.
   * @param email
   * @param clientId
   * @returns Observable
   */
  forgotPasswordEcommerce(email: string, clientId: number): Observable<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.ecommerceAuthUrl + '/forgotPasswordEcommerce', {email, clientId}, httpOptions);
  }

  /**
   * Função auxiliar para fazer a redefinição da senha.
   * @param data Dados para realizar o update da senha
   * @returns Observable
   */
  passwordReset(data: IPasswordResetData): Observable<IResponseData> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post<IResponseData>(this.baseUrl + this.ecommerceAuthUrl + '/resetPasswordEcommerce', data, httpOptions);
  }

  getPdUserByEmail(data) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.pdUsersUrl + '/getOneByEmail', data, httpOptions);
  }

  getAllProducts(urlParams: string, data) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.productsUrl + urlParams, data, httpOptions);
  }

  getProductsCategories(isLoggedIn = false, clientId: number = null) {

    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    let entity = this.productCategoryUrl;
    let urlComplement = '';

    if (!isLoggedIn && clientId) {
      entity = this.withoutAuthFunctionsUrl + entity;
      urlComplement += `/${clientId}`;
    }

    return this.http.get(this.baseUrl + entity + urlComplement, httpOptions);
  }

  getProductsCategoriesParents(clientId: number = null) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.withoutAuthFunctionsUrl + '/product_category_parents/' + clientId, httpOptions);
  }

  getProductCategoriesForTable(isLoggedIn = false, clientId: number = null) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    let entity = this.productCategoryUrl;
    let urlComplement = '';

    if (!isLoggedIn && clientId) {
      entity = this.withoutAuthFunctionsUrl;
      urlComplement += `/${clientId}`;
    }

    return this.http.get(this.baseUrl + entity + '/categories_for_table' + urlComplement, httpOptions);
  }

  getProductCategoriesForSelect(id = 0) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.productCategoryUrl + '/categories_for_select/' + id, httpOptions);
  }

  getCategoryChildrenById(id = 0, isLoggedIn = false, clientId = 108) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    let entity = this.productCategoryUrl;
    let urlComplement = '';

    if (!isLoggedIn && clientId) {
      entity = this.withoutAuthFunctionsUrl;
      urlComplement += `/${clientId}`;
    } else {
      urlComplement += `/${id}`;
    }

    return this.http.get(this.baseUrl + entity + '/category_children' + urlComplement, httpOptions);
  }

  // PriceTable

  getPriceTableByUserId(userId) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.ecommerceAuthUrl + '/getPriceTableByUserId' + userId, httpOptions);
  }

  getPriceTablesAll() {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.priceTablesUrl + '/all', httpOptions);
  }

  // ****** PRODUCTS ******

  // Get only allowed products to the logged in user with pagination
  getProductsEcommercePaginated(data = {}) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.productsUrl + '/get_products_ecommerce_paginated', data, httpOptions);
  }

  /**
   * Get all products filtered by id client
   * @param data
   */
  getAllProductsPaginated(data= {}): Observable<IResponseData> {
    return this.http.post<IResponseData>(this.baseUrl + this.withoutAuthFunctionsUrl + '/get_products_ecommerce_paginated', data, httpOptions);
  }

  // Get only allowed products to the logged in user
  getProductsEcommerce(data = {}) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.productsUrl + '/get_products_ecommerce', data, httpOptions);
  }

  getProductById(id: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.productsUrl + '/' + id, httpOptions);
  }

  getProductsImagesDt(params: any, urlParams: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(
      this.baseUrl + this.productImagesUrl + '/product_images/all_data_tables' + urlParams,
      params,
      httpOptions
    );
  }

  // ****** USERS ******
  getUserOrdersPaginated(data) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.usersUrl + '/orders_ecommerce_paginated', data, httpOptions);
  }

  getOrderOne(code: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.usersUrl + '/order/' + code, httpOptions);
  }

  getUserStores(id: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.usersUrl + '/stores/' + id, httpOptions);
  }

  updateUserData(id: string, data) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.put(this.baseUrl + this.usersUrl + '/' + id, data, httpOptions);
  }

  // catalogs

  getCatalogById(clientId: string, catalogId: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<catalog>(`${this.baseUrl}${this.withoutAuthFunctionsUrl}/${catalogId}?clientId=${clientId}`, httpOptions);
  }

 // FIM
  getUserCatalogs(id: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.usersUrl + '/catalogs/' + id, httpOptions);
  }

  updateCatalog(id: string, data) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.usersUrl + '/catalogs/' + id, data, httpOptions);
  }

  getUserSuggestedLists(id: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.usersUrl + '/suggested_lists/' + id, httpOptions);
  }

  getProductCustomFields() {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.productCustomFieldsUrl, httpOptions);
  }

  getProductCustomValues(url_params) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.productCustomValuesUrl + '/products/product_custom_fields' + url_params, httpOptions);
  }

  // ******* Payment methods *******
  getPaymentMethods() {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.paymentMethodsUrl + '/', httpOptions);
  }

  // ******* Payment terms *******
  getPaymentTerms() {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.paymentTermsUrl + '/', httpOptions);
  }

  // Order Types
  getOrderTypes(isLoggedIn = false, clientId: number = null) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    let entity = this.orderTypeUrl;
    let urlComplement = '';

    if (!isLoggedIn && clientId) {
      entity = this.withoutAuthFunctionsUrl+entity;
      urlComplement += `/${clientId}`;
    }

    return this.http.get(this.baseUrl + entity + urlComplement + '/getAll', httpOptions);
  }

  getTaxRules(id: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.taxRulesUrl + '/' + id, httpOptions);
  }

  /*
  * PAGARME ****/
  createPaymentLink(data) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(this.baseUrl + this.pagarmeB2bUrl + '/payment_link/', data, httpOptions);
  }
  /* ****** */

  /**
   * Adiciona confirmação do pedido
   * @param data
   */
  addConfirmation(data: IOrderConfirmationData): Observable<IResponseData> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post<IResponseData>(this.baseUrl + this.orderReceiveConfirmationUrl + '/confirmation', data, httpOptions);
  }

  getOrderConfirmation(orderId: number): Observable<IResponseData> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<IResponseData>(this.baseUrl + this.orderReceiveConfirmationUrl + `/${orderId}`, httpOptions);
  }

  // reviews
  getOrderReview(orderId) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<IResponseData>(this.baseUrl + this.orderReviewUrl + `/${orderId}`, httpOptions);
  }

  getGrades(urlParams: string) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.gradeUrl + urlParams, httpOptions);
  }

  getProductReviews(productId) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<IResponseData>(this.baseUrl + this.orderReviewUrl + '/product/' + productId, httpOptions);
  }

  saveOrderReview(id: string, data) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.put(this.baseUrl + this.orderReviewUrl + '/update/' + id, data, httpOptions);
  }

  getFinalStatus(): Observable<IResponseData> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<IResponseData>(this.baseUrl + this.orderStatusUrl + '/final', httpOptions);
  }

  addOutOfStockNotification(data: IAddNoStockNotificationData): Observable<IResponseData> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post<IResponseData>(this.baseUrl + this.withoutAuthFunctionsUrl + '/product-stock-notification', data, httpOptions)
      .pipe(take(1));
  }

  getBranches() {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<IResponseData>(this.baseUrl + this.branchUrl + '/all', httpOptions);
  }

  getLikedProducts() {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<IResponseData>(this.baseUrl + this.likedProductsUrl + '/all', httpOptions);
  }

  getPriceTableById(priceTableId) {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get(this.baseUrl + this.priceTablesUrl + '/getPriceTableById/' + priceTableId, httpOptions);
  }

  // whishlists

  getWishlists() {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<WishlistResponse>(this.baseUrl + this.wishlistsUrl + '/all', httpOptions);
  }
  createWishlist(name: string): Observable<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    const data = { name: name };
    return this.http.post(this.baseUrl + this.wishlistsUrl + '/createWishlists', data, httpOptions);
  }

  updateWishlistName(name: string, wishlistId: number): Observable<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    const data = { name, wishlistId };
    return this.http.patch(this.baseUrl + this.wishlistsUrl + '/updateWishlistName', data, httpOptions);
  }

  deleteWishlist(wishlistId: number): Observable<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.delete(this.baseUrl + this.wishlistsUrl + `/deleteWishlists/` + wishlistId, httpOptions);
  }

  // Wishlist Products (pag favorites)

  getProductsWishlistById(productId: string): Observable<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<wishlistsProductsResponse>(this.baseUrl + this.wishlistsProductsUrl + `/getProductInWishlist/${productId}`, httpOptions);
  }

  getWishlistProductsById(wishlistId: string): Observable<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<wishlistsProductsResponse>(this.baseUrl + this.wishlistsProductsUrl + `/all/${wishlistId}/products`, httpOptions);
  }

  addProduct(wishlistId: string, product: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.post(`${this.baseUrl}${this.wishlistsProductsUrl}/selectedProducts`, { wishlistId, product }, httpOptions);
  }

  delProduct(wishlistId: string, product: any): Observable<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.delete(this.baseUrl + this.wishlistsProductsUrl + `/deletedProducts/` + wishlistId + '/' + product, httpOptions);
  }


  // cupons de desconto
  getCoupons() {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<DiscountCouponsResponse>(this.baseUrl + this.DiscountCouponsUrl + '/all', httpOptions);
  }

  getCouponsById(couponId: string): Observable<any> {
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<DiscountCouponsResponse>(this.baseUrl + this.DiscountCouponsUrl + `/all/${couponId}`, httpOptions);
  }

  UserGroupAll(){
    httpOptions.headers = httpOptions.headers.set('Authorization', 'Bearer ' + this.userToken);
    return this.http.get<UserGroup>(this.baseUrl + this.userGroupUrl + '/', httpOptions);
  }



}




