import { EventEmitter, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SectionInformationService {

  hasLoaded = new EventEmitter<boolean>();
  public user_settings = {};

  constructor() { }

  setInfos(userDoc, company, rh) {
    this.user_settings['infos_user'] = userDoc;
    this.user_settings['infos_company'] = company;
    this.user_settings['infos_rh'] = rh;

    setTimeout(() => {
      this.hasLoaded.emit(true);
    }, 500);
  };

  getInfos() {
    return this.user_settings;
  }
}
