import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { UserService } from '../../services/user/user.service';

declare var $: any;

@Component({
  selector: 'app-suggested-lists',
  templateUrl: './suggested-lists.component.html',
  styleUrls: ['./suggested-lists.component.css']
})
export class SuggestedListsComponent implements OnInit {

  loadedData: boolean = false;

  user;

  suggested_lists = [];
  list = {};

  constructor(
    private userService: UserService,
    private apiService: ApiService,
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
      }

      this.getUserSuggestedLists();
    });
  }

  getUserSuggestedLists() {
    this.apiService.getUserSuggestedLists(this.user.id).subscribe((res) => {
      if (!res['error']) {
        this.suggested_lists = res['suggested_lists'];
      }
      
      this.loadedData = true;
    });
  }

  loadListToCart(list) {

  }

  listDetails(list) {
    this.list = list;

    $('#list-details').modal('show');
  }
}
