import { Injectable } from '@angular/core';

declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class CartService {

  constructor() {
    this.updateCardQtd();
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  getCart() {
    const carts = JSON.parse(localStorage.getItem('carts'));
    const user = JSON.parse(localStorage.getItem('user'));

    if (carts) {
      const userEmail = user ? user.email : '-';
      const userPdId = user ? user.pd_id : '-';
      const key = `${userEmail}-${userPdId}`;

      return carts ? carts[key] : {products: []};
    }

    return {
      products: []
    };
  }

  setProduct(product, qtd = 1, persist = true) {
    const cart = this.getCart();

    if (typeof qtd !== 'number') {
      qtd = parseInt(qtd);
    }

    if (qtd <= 1) {
      product.quantity = 1;
    } else {
      product.quantity = qtd;
    }

    if (cart && cart !== null) {
      if (cart.products && cart.products.length > 0) {
        const find = cart.products.find(i => String(i.id) === String(product.id));

        if (find) {
          find.quantity = persist ? parseInt(find.quantity) + qtd : qtd;

          localStorage.removeItem('cart');
        } else {
          cart.products.push(product);
        }

        this.addNewCart(cart);
      } else {
        cart.products = [];
        cart.products.push(product);

        this.addNewCart(cart);
      }
    } else {
      const cart = {
        products: [product],
      };

      this.addNewCart(cart);
    }
  }

  isCartEmpty() {
    const cart = this.getCart();

    if (cart && cart !== null) {
      if (cart.products && cart.products.length > 0) {
        return false;
      }
    }

    return true;
  }

  isInsideCart(productId) {
    const cart = this.getCart();

    if (cart !== null) {
      if (cart.products && cart.products.length > 0) {
        const exists = cart.products.find(item => String(item.id) === String(productId));

        if (exists) {
          return true;
        }
      }
    }

    return false;
  }

  removeProducts() {
    const cart = this.getCart();

    if (cart !== null) {
      cart['products'] = [];

      localStorage.removeItem('cart');
      this.addNewCart(cart);
      this.updateCardQtd();
    }
  }

  removeProduct(productId) {
    const cart = this.getCart();

    if (cart !== null) {
      const prod = cart['products'].find(item => item.id === productId);

      const index = cart['products'].indexOf(prod);

      if (index !== -1) {
        cart['products'].splice(index, 1);
      }

      localStorage.removeItem('cart');
      this.addNewCart(cart);
      this.updateCardQtd();
    }
  }

  addNewCart(cart) {
    const user = JSON.parse(localStorage.getItem('user'));

    if (user) {
      const userEmail = user.email;
      const userPdId = user.pd_id;
      const key = `${userEmail}-${userPdId}`;
      let carts = JSON.parse(localStorage.getItem('carts'));

      if (!carts || typeof carts === 'undefined') {
        carts = {};
      }

      if (typeof carts[key] === 'undefined') {
        carts[key] = {
          products: [],
        };
      }

      carts[key] = cart;
      localStorage.setItem('carts', JSON.stringify(carts));
      this.updateCardQtd();
    }
  }

  getProductQtd(productId) {
    const cart = this.getCart();

    if (cart !== null) {
      const prod = cart['products'].find(item => item.id === productId);

      return prod.quantity;
    }

    return 0;
  }

  private updateCardQtd() {
    const cart = this.getCart();
    let qtd = 0;

    if (cart && cart.products.length > 0) {
      cart.products.forEach(() => {
        qtd++;
      });
    }

    $('#cart-qtd').html(qtd);
    $('#cart-qtd-mobile').html(qtd);
  }
}
