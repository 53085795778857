import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AngularFireModule } from '@angular/fire';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';

import ptBr from '@angular/common/locales/pt';
import { registerLocaleData } from '@angular/common';

import { AngularFireAuthModule } from '@angular/fire/auth';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { SellenttPaginationComponent } from './components/sellentt-pagination/sellentt-pagination.component';

import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { RegisterComponent } from './components/register/register.component';

import { AngularFirestore } from '@angular/fire/firestore';
import { ToastrModule } from 'ng6-toastr-notifications';
import { ToastrService } from './services/toastr/toastr-service.service';
import { HomeComponent } from './components/home/home.component';
import { CookieService } from 'ngx-cookie-service';
import { CategoriesComponent } from './components/categories/categories.component';
import { LoaderInterceptor } from './interceptors/loader/loader.interceptor';
import { RouterModule } from '@angular/router';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { CommonModule } from '@angular/common';
import { StoresComponent } from './components/stores/stores.component';
import { CatalogsComponent } from './components/catalogs/catalogs.component';
import { SuggestedListsComponent } from './components/suggested-lists/suggested-lists.component';
import { OrdersComponent } from './components/orders/orders.component';
import { CartComponent } from './components/cart/cart.component';
import { PrecartComponent } from './components/precart/precart.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { OrderReviewComponent } from './components/order-review/order-review.component';
import { SafeStylePipe } from './pipes/safe-style.pipe';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import { OrderConfirmationComponent } from './components/order-confirmation/order-confirmation.component';
import { FavoritesComponent } from './components/favorites/favorites.component';
import { ProductComponent } from './components/product/product.component';
import { ProductsFilterComponent } from './components/products-filter/products-filter.component';
import { ReactiveFormsModule } from '@angular/forms';
import {FavoritesDetailsComponent } from './components/favorites-details/favorites-details.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { CatalogLinkComponent } from './components/catalog-link/catalog-link.component';

registerLocaleData(ptBr);

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RegisterComponent,
    HomeComponent,
    SellenttPaginationComponent,
    CategoriesComponent,
    ProductDetailsComponent,
    UserProfileComponent,
    StoresComponent,
    CatalogsComponent,
    SuggestedListsComponent,
    OrdersComponent,
    CartComponent,
    PrecartComponent,
    OrderDetailsComponent,
    OrderReviewComponent,
    SafeStylePipe,
    ForgotPasswordComponent,
    PasswordResetComponent,
    OrderConfirmationComponent,
    FavoritesComponent,
    FavoritesDetailsComponent,
    ProductComponent,
    ProductsFilterComponent,
    MaintenanceComponent,
    CatalogLinkComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AngularFireModule.initializeApp(environment.firebase, 'biaction'),
    FormsModule,
    AppRoutingModule,
    RouterModule,
    AngularFireAuthModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    ReactiveFormsModule,
  ],
  providers: [
    AngularFirestore,
    CookieService,
    ToastrService,
    {provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true},
    {provide: LOCALE_ID, useValue: 'pt'},
  ],
  exports: [
    HomeComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
