import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { AuthService } from '../../services/auth/auth.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {

  token: string;
  email: string;
  password: string;
  confirmPassword: string;
  clientId: number;

  constructor(
    private route: ActivatedRoute,
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private toastr: ToastrService,
  ) {
    this.route.queryParams.subscribe(params => {
      this.token = params['token'];
      this.email = params['email'];
    });
    this.clientId = this.localStorageService.get('pd_id');
  }

  ngOnInit() {

  }

  resetPassword(form: NgForm): void {
    if (this.confirmPassword !== this.password) {
      this.toastr.showError('As senhas não podem ser diferentes.');
      return;
    }

    this.apiService.passwordReset({
      email: this.email,
      password: this.password,
      token: this.token,
      clientId: this.clientId
    }).subscribe(
      res => {
        if (res.error) {
          this.toastr.showError(res.message)
        } else {
          this.toastr.showSuccess(res.message)
          form.controls['password'].reset();
          form.controls['confirm-password'].reset();
        }
      },
      err => {
        this.toastr.showError('Desculpe, não foi possível alterar a senha. Contate o suporte.')
        console.error(err);
      }
    );
  }
}
