import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {

  email: string = '';
  clientId: number;

  constructor(
    private apiService: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private localStorageService: LocalStorageService
  ) {
    this.clientId = this.localStorageService.get('pd_id');
  }

  ngOnInit() {
  }

  sendEmail() {
    this.apiService.forgotPasswordEcommerce(this.email, this.clientId).subscribe(
      response => {
        if (response.error) {
          this.toastr.showError(response.message);
        } else {
          this.toastr.showSuccess(response.message);
        }
      },
      error => this.toastr.showError('Ocorreu um erro no envio do e-mail. Contate o suporte.')
    )
  }

}
