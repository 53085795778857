import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { CartService } from '../../services/carts/cart.service';
import { Router , ActivatedRoute} from '@angular/router';
import { SalePolicyService } from '../../services/sale-policy/sale-policy.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';
import { FirebaseApp } from '@angular/fire';
import { AuthService } from '../../services/auth/auth.service';
import { ApiService } from '../../services/api/api.service';
import {UserService} from '../../services/user/user.service';
import * as numeral from 'numeral';

declare var $: any;

@Component({
  selector: 'app-favorites-details',
  templateUrl: './favorites-details.component.html',
  styleUrls: ['./favorites-details.component.css']
})

export class FavoritesDetailsComponent implements OnInit {

  @Input()
  product: any;

  @Input()
  orderTypeId: any;

  @Input()
  priceTableId?: any;

  @Input()
  type: String;

  showSpinner: boolean = false;
  user;
  companyId: any;
  clientId: any;
  isStockActive: boolean;
  showQtdField: boolean;
  isLoggedIn: boolean;
  wishlists: any[] = [];
  newWishlistName: string = '';
  newName: string = '';
  selectedWishlistId: number | null = null;
  deletedWishlistId: number | null = null;
  newFavorite: any[] = [];
  selectedProducutId: number | null = null;
  productsIds: any[] = [];
  wishlistsId: string;

  productList: boolean;
  hasProducts: boolean;

  banners = [];
  loggedOutPriceTable: String;
  term = '';
  selectedOrderType = {};
  orderTypes = [];
  client_selected = {};
  client_cnpj = '';

  currentGrade = {
    description: 'Grade De Produtos',
    products: [],
  };

  defaultPriceTable: String;
  ecommerceConfigs = {};

  ncm_list: any;
  isento_st = false;

  basePath = `https://p.biaction.com.br/`;
  storeName = 'Ecommerce';
  urlBase = '';

  constructor(
    private userService: UserService,
    private firebase: FirebaseApp,
    private cartService: CartService,
    private route: ActivatedRoute,
    private router: Router,
    private salePolicyService: SalePolicyService,
    private localStorageService: LocalStorageService,
    private toastrService: ToastrService,
    private toastr: ToastrService,
    private authService: AuthService,
    private apiService: ApiService,
  ) {
    this.ecommerceConfigs['biaction_id'] = this.localStorageService.get('biaction_id');
    this.companyId = this.localStorageService.get('biaction_id');
    this.clientId = this.localStorageService.get('pd_id');
    this.term = this.route.snapshot.params['term'];
    this.isLoggedIn = this.authService.isLoggedIn();

    const url = this.router['location']._platformLocation.location.origin;
    this.urlBase = url.split('://')[1].split('.')[0];
  }

  async ngOnInit() {
    await this.getEcommerceConfigs();
    this.loadWishlistProducts();
  }

  loadWishlistProducts() {
    const wishlistId = this.route.snapshot.paramMap.get('id');

    if (wishlistId) {
      this.apiService.getWishlistProductsById(wishlistId).subscribe( (res) => {

        if (!res.error) {

          if (Array.isArray(res.wishlistProducts)) {

            this.getWishlist(wishlistId);

            this.wishlistsId = res.wishlistIds

            this.productsIds = res.wishlistProducts;

            this.isInsideCart(this.productsIds);

            this.hasProducts = this.productsIds.length > 0;

          } else {
            this.toastrService.showError('Erro ao buscar os produtos da lista');
          }

        } else {
          this.toastrService.showError('Erro ao buscar os produtos da lista');
        }
    });
    } else {
      this.toastrService.showError('Erro ao buscar os produtos da lista');
    }
  }

  // remove o produto na lista

  delProductList(product) {
    const productId = product.id;
    const wishlistId = this.wishlistsId;

    this.apiService.delProduct(wishlistId, productId).subscribe((res) => {
        if (!res.error) {
            this.newFavorite = res.selected;
            this.toastrService.showSuccess('Produto deletado com sucesso!');
            setTimeout(() => {
              this.ngOnInit();
            }, 100);
        } else {
            this.toastrService.showError('Erro ao excluir o item. Por favor, tente novamente');
        }
    }, (err) => {
        console.error('Erro na solicitação ao servidor:', err);
        this.toastrService.showError('Erro ao excluir o item. Por favor, tente novamente');
    });
  }


  async getEcommerceConfigs() {

    await this.authService.getInfosByUrl('?url_base=' + this.urlBase);

    this.ecommerceConfigs = this.authService.setEcommerceConfigs();

    this.getEcommercePolicy();
  }

  getEcommercePolicy() {
    this.salePolicyService.getEcommercePolice(this.ecommerceConfigs['biaction_id']).then((res) => {
      this.showQtdField = res && res['show_qtd_field'] ? res['show_qtd_field'] : false;
      this.loggedOutPriceTable = res && res['logged_out_price_table'] ? res['logged_out_price_table'] : '';
      this.getPricingPolicy();
    });
  }

  getPricingPolicy() {
    this.salePolicyService.getPolices(this.ecommerceConfigs['biaction_id']).then((res) => {
      this.defaultPriceTable = res && res['default_price_table'] ? res['default_price_table'] : '';

      // Verifica se o Usuário está LOGADO
      if (this.isLoggedIn) {
        this.getUser();
      } else {
        this.toastr.showWarning('faça o login');
      }
    });
  }


  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
        this.client_selected = this.user.store ? this.user.store : {};

        if (this.client_selected['doc_number'] && this.client_selected['doc_number'] &&
          this.client_selected['doc_number'] !== '' && this.client_selected['doc_number'].length > 0) {
          this.client_cnpj = this.client_selected['doc_number'].replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
            '\$1.\$2.\$3/\$4-\$5');
          if (this.client_selected['doc_number'].length === 11) {
            this.client_cnpj = this.client_selected['doc_number'].replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g,
              '\$1.\$2.\$3-\$4');
          }
        }

        if (!this.user.company) {
          this.toastr.showError('Cliente com grupo de cliente não configurado, por favor, atualize o cadastro e tente novamente.');
          setTimeout(() => {
            this.authService.authLogout();
            window.location.href = '/login';
            return;
          }, 4000);
        }
        this.getPriceTableById();
      }
    });
  }

  getPriceTableById() {
    this.apiService.getPriceTablesAll().subscribe((res) => {
      if (!res['error']) {
        const priceTables = res['price_tables'];

        if (this.user.store.default_price_table === null) {
          this.toastr.showError('Cliente sem tabela de preço padrão, por favor, atualize o cadastro e tente novamente.');
          return;
        }

        const storePriceTable = priceTables.find(
          (priceTable) => priceTable.id === this.user.store.default_price_table
        );

        this.user.store.default_price_table_code = storePriceTable.code;

        this.getTaxRules();
        this.getOrderTypes();
      }
    });
  }

  getTaxRules() {
    const store = this.user && this.user.store ? this.user.store : {};
    const tax_rules_id = store.tax_rules_id ? store.tax_rules_id : '';

    if (tax_rules_id !== '') {
      this.apiService.getTaxRules(tax_rules_id).subscribe((res) => {
        if (!res['error']) {
          const fiscalTaxRules = res['fiscalTaxRules'];
          if (fiscalTaxRules !== undefined) {
            this.isento_st = fiscalTaxRules.isento_st
              ? fiscalTaxRules.isento_st
              : false;
            this.ncm_list = fiscalTaxRules.ncm;
          }
        }
      });
    }
  }

  getOrderTypes() {
    const that = this;

    this.apiService
      .getOrderTypes(this.isLoggedIn, this.ecommerceConfigs['pd_id'])
      .subscribe((res) => {
        if (!res['error']) {
          res['order_types'].forEach((type) => {
            if (typeof type.data.ecommerce !== 'undefined' && type.data.ecommerce.active === true) {
              that.orderTypes.push({
                id: type.id,
                name: type.data.name,
                categories: type.data.ecommerce.categories,
              });

              that.selectedOrderType = {
                id: type.id,
                name: type.data.name,
                categories: type.data.ecommerce.categories,
              };

              this.localStorageService.set('selectedOrderTypeId', this.selectedOrderType['id']);

              this.getAllowedProductsToUser();
            }
          });
        }
      });
  }


  getAllowedProductsToUser() {

    const productsRequest = this.firebase
      .functions()
      .httpsCallable('products-getCart');

    const data = {
      ids: this.productsIds,
      price_table_id: this.user.store.default_price_table_code,
      source: 'new-ecommerce',
      company: {
        id: this.ecommerceConfigs['biaction_id'],
      },
      order_type_id: this.selectedOrderType['id'],
    };


    productsRequest(data)
      .then((res) => {
        if (res['data']['products']) {
          this.currentGrade.products = res['data']['products'].map((prod) => {
            prod['image'] = prod['photo_cover']
              ? 'https://p.biaction.com.br/' +
              this.ecommerceConfigs['pd_id'] +
              '/products/300x300/' +
              prod['photo_cover']
              : 'https://app.pedidosdigitais.com.br/img/sem-imagem-mini.png';
            prod['has_discount'] =
              prod['default_discount'] > 0 &&
              prod['price_no_discounts'] > prod['price_with_discounts']
                ? true
                : false;
            prod['price_default'] =
              prod['price_default'] && prod['price_default'] > 0
                ? prod['price_default']
                : 0;
            prod['price_no_discounts'] = prod['product_price_table'];
            prod['price_with_discounts'] = prod['product_price_table'] - (prod['product_price_table'] * prod['default_discount']) / 100;
            prod['total_discount_percentage'] = prod['has_discount']
              ? numeral(
                ((prod['price_with_discounts'] -
                    prod['price_no_discounts']) /
                  prod['price_no_discounts']) *
                100
              ).format('0')
              : 0;
            prod['stock'] =
              prod['stock_place_order_type'] > 0
                ? prod['stock_place_order_type']
                : 0;

            const ipi = prod.ipi;
            if (
              prod.ncm !== '' &&
              this.ncm_list !== undefined &&
              Object.keys(this.ncm_list).length > 0
            ) {
              const ncm = this.ncm_list[prod.ncm];
              if (ncm !== undefined) {
                const state = this.user.store.address_state;
                const ipi_ncm = ncm.ipi;
                const isento_ipi = ncm.isento_ipi ? ncm.isento_ipi : false;
                if (!isento_ipi) {
                  if (ipi_ncm && !isNaN(ipi_ncm)) {
                    prod['ipi'] = ipi_ncm;
                  } else {
                    prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                  }
                } else {
                  prod['ipi'] = 0;
                }
                if (!this.isento_st) {
                  if (prod.fromabroad) {
                    prod['st'] =
                      ncm.im !== undefined && ncm.im[state] !== undefined
                        ? ncm.im[state]
                        : 0;
                  } else {
                    prod['st'] =
                      ncm.st !== undefined && ncm.st[state] !== undefined
                        ? ncm.st[state]
                        : 0;
                  }
                } else {
                  prod['st'] = 0;
                }
                prod['tax_discount'] =
                  ncm.tax_discount !== undefined &&
                  ncm.tax_discount[state] !== undefined
                    ? ncm.tax_discount[state]
                    : 0;
              } else {
                prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
                prod['st'] = 0;
                prod['tax_discount'] = 0;
              }
            } else {
              prod['ipi'] = ipi !== -1 && ipi !== null ? ipi : 0;
              prod['st'] = 0;
              prod['tax_discount'] = 0;
            }
            return prod;
          });
        }
        this.showSpinner = true;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  addAllToCart() {
    const products = this.currentGrade.products;
    for (const product of products) {

      if (!product.quantity || product.quantity <= 0) {
        product.quantity = 1;
      }
      this.addToCart(product);
    }
  }
  addToCart(product) {

    let qtdField = $(`#add_cart_${product.id}`).val();
    let msg = '';
    let qtd = 0;

    const minimum = product.minimum ? product.minimum : 0;
    const multiple = product.multiple ? product.multiple : 0;
    const maximum = product.maximum ? product.maximum : 0;

    if (qtdField && qtdField !== '') {
      qtd = qtdField;
    }else{
      this.toastr.showWarning('A Quantidade não pode ser 0');
      return;
    }

    if (qtdField && qtdField <= 0) {
      this.toastr.showWarning('A Quantidade não pode ser menor que 0');
      return;
    }

    if (minimum > 0 && qtdField > 0) {
      if (qtdField < minimum) {
        msg += 'A Quantidade mínima para esse produto é ' + minimum + '.<br>';
      }
    }

    if (maximum > 0 && qtdField > 0) {
      if (qtdField > maximum) {
        msg += 'A Quantidade máxima para esse produto é ' + maximum + '.<br>';
      }
    }

    if (multiple > 0 && qtdField > 0) {
      const rest =
        (parseFloat((100 * qtdField).toFixed(2)) %
          parseFloat((100 * multiple).toFixed(2))) /
        100;
      if (rest !== 0) {
        msg +=
          'A Quantidade deve ser um valor múltiplo de ' + multiple + '.<br>';
      }
    }

    if (msg !== '') {
      this.toastr.showWarning(msg);
    } else {
      this.cartService.setProduct(product, qtd, false);

      product.isInsideCart = true;
      this.toastr.showSuccess('Produto inserido ao carrinho com sucesso!');
    }
  }

  changeQtd(product) {
    const qtd = $(`#add_cart_${product.id}`).val();
    product.quantity = qtd;
  }

  isInsideCart(productId) {
    return this.cartService.isInsideCart(productId);
  }

  getProductCartQtd(product) {
    this.cartService.getProductQtd(product);
  }

  getWishlist(wishlistId: string) {
    this.wishlists = [];

    this.apiService.getWishlists().subscribe((res) => {
        if (!res.error) {

           this.wishlists = res.wishlists.filter((item) => item.id == wishlistId);

        } else {
            console.error('Erro ao obter as listas de desejos:', res.message);
        }
    }, (err) => {
        console.error('Erro na solicitação ao servidor:', err);
    });
  }

}
