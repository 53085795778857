import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from '../../services/api/api.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { AuthService } from '../../services/auth/auth.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';

declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  clientId;
  logoUrl = '';
  msg = '';
  error = '';

  user = {
    email: '',
    password: '',
  }

  basePath = `https://p.biaction.com.br/`;
  storeName = 'Ecommerce';
  urlBase = '';

  facebook_link = '';
  instagram_link = '';
  linkedin_link = '';
  twitter_link = '';

  loadedData: boolean = false;

  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
  ) {
    const url = this.router['location']._platformLocation.location.origin;
    this.urlBase = url.split('://')[1];
  }

  ngOnInit() {
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/home']);
    }

    if (!this.localStorageService.ifExists()) {
      this.getInfosByUrl();
    } else {
      this.setConfigs();
    }
  }

  setConfigs() {
    this.clientId = this.localStorageService.get('pd_id');
    const store_logo = this.localStorageService.get('store_logo');
    this.logoUrl = this.basePath + this.clientId + '/' + store_logo;
    this.storeName = this.localStorageService.get('store_name');

    this.loadedData = true;

    setTimeout(() => {
      $('#setImage').append('<img src="' + this.logoUrl +'" class="pb-4" width="200px">');
    }, 250);
  }

  getInfosByUrl() {
    this.apiService.getB2bConfigsByUrl('?url_base=' + this.urlBase).subscribe((res: any) => {
      if (!res['error']) {
        const dataResult = res['configs'];

        this.facebook_link = dataResult['facebook_link'];
        this.instagram_link = dataResult['instagram_link'];
        this.linkedin_link = dataResult['linkedin_link'];
        this.twitter_link = dataResult['twitter_link'];

        this.localStorageService.set('store_favicon', dataResult['store_favicon']);
        this.localStorageService.set('store_logo', dataResult['store_logo']);
        this.localStorageService.set('store_popup_image', dataResult['store_popup_image']);
        this.localStorageService.set('store_primary_color', dataResult['store_primary_color']);
        this.localStorageService.set('pd_id', parseInt(dataResult['id']));
        this.localStorageService.set('biaction_id', parseInt(dataResult['biaction_id']));
        this.localStorageService.set('store_name', dataResult['store_name']);
        this.localStorageService.set('show_check_order_received', dataResult['show_check_order_received'])

        this.setConfigs();
      } else {
        console.error('Erro ao carregar as configurações do cliente.\n', res['message']);
      }

      this.loadedData = true;
    });
  }

  recovery() {

  }

  signInWithEmail() {
    const btn = $('#sign-button');
    const htmlAccess = '<i class="fas fa-sign-in" aria-hidden="true"></i> Acessar ';
    const htmlAccessing = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Acessando...';

    btn.html(htmlAccessing);

    const data = {
      email: $('#user-mail').val(),
      password: $('#user-password').val(),
    }
    const isEmail = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    let msg = '';

    if (!data.email || data.email === '') {
      msg += 'O campo <b>Email</b> deve ser informado! <br>';
      $('#user-mail').css('border-color', 'red');
    } else {
      $('#user-mail').css('border-color', '#e5e6e7');
    }

    if (!isEmail.test(data.email)) {
      msg += 'O campo <b>Email</b> deve ser um email válido! <br>';
      $('#user-mail').css('border-color', 'red');
    } else {
      $('#user-mail').css('border-color', '#e5e6e7');
    }

    if (!data.password || data.password === '') {
      msg += 'O campo <b>Senha</b> deve ser informado! <br>';
      $('#user-password').css('border-color', 'red');
    } else {
      $('#user-password').css('border-color', '#e5e6e7');
    }

    if (msg === '') {
      this.authService.authLogin('?client_id=' + this.clientId, data, (res) => {
        console.log('res => ', res)
        if (res['error']) {
          this.toastr.showError(res['message']);
          btn.html(htmlAccess);
        } else {
          console.log(res['message']);
          window.location.href = '/home';
        }
      });
    } else {
      this.toastr.showWarning(msg);
      btn.html(htmlAccess);
    }
  }
}
