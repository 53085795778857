import { Component, Input, Output, EventEmitter, ChangeDetectorRef, OnInit } from '@angular/core';

@Component({
  selector: 'sellentt-pagination',
  templateUrl: './sellentt-pagination.component.html',
  styleUrls: ['./sellentt-pagination.component.css'],
})
export class SellenttPaginationComponent implements OnInit{

  @Input() page: number;
  @Input() hitsPerPage: number;
  @Input() nbHits: number;
  @Input() nbPages: number;
  @Input() maxSize: number;
  @Output() pageChanged: EventEmitter<number> = new EventEmitter();
  
  current_page;
  page_array;
  final_page;
  

  constructor() {}

  ngOnInit(): void {
    this.setupPagination();
  }

  getPages(page: number, nbPages: number): Array<number> {
    const pages = [];

    let startPage = 1;
    let endPage = nbPages;
    const isMaxSized = typeof this.maxSize !== 'undefined' && this.maxSize < nbPages;

    if (isMaxSized && this.maxSize) {
      startPage = Math.max(page - Math.floor(this.maxSize / 2), 1);
      endPage = startPage + this.maxSize - 1;

      if (endPage > nbPages) {
        endPage = nbPages;
        startPage = endPage - this.maxSize + 1;
      }
    }

    for (let number = startPage; number <= endPage; number++) {
      pages.push(number);
    }

    return pages;
  }

  setupPagination() {
    // Paginação
    let page = 0;
    let count_next = 0;
    this.current_page = this.page;
    this.page_array = [];
    
    if (this.maxSize) {
      this.final_page = this.maxSize;
    }

    while (page <= this.final_page) {
      page++;
      if (String(page) === String(this.current_page)) {
        let count_previous = 2, sum_page = 0;
        while (count_previous >= 0) {
          count_previous--;
          sum_page++;

          const subtract_page = this.current_page - sum_page;

          if (subtract_page > 0) {
            this.page_array.push(subtract_page);
          }
        }
      }

      if (page >= this.current_page && page <= this.final_page) {
        count_next++;
        if (count_next < 5) {
          this.page_array.push(page);
        }
      }
    }

    // Ordena o objeto
    this.page_array.sort(function (a, b) {
      return a - b;
    })
  }

  setPage(page: number, event?: Event): void {
    if (event) {
      event.preventDefault();
    }

    if (event && event.target) {
      const target: any = event.target;
      target.blur();
    }

    this.current_page = page;
    this.getPages(this.page, this.nbPages);
    this.pageChanged.emit(page);
  }

}
