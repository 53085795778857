import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private storage: Storage;
  constructor() {
    this.storage = window.localStorage;
  }

  ifExists(): boolean {
    const items = JSON.parse(localStorage.getItem("pd_id"));
    let exists = false;

    if (items) {
      exists = true;
    }
    return exists;
  }

  set(key: string, value: any): boolean {
    // Testa se o navegador suporta o localStorage antes de usar
    if (this.storage) {
      this.storage.setItem(key, JSON.stringify(value));
      return true;
    }
    // todo outra alternativa é tentar usar cookies ao invés de retornar false
    return false;
  }

  remove(key: string): boolean {
    if (this.storage) {
      this.storage.removeItem(key);
      return true;
    }
    return false;
  }

  get(key: string): any {
    if (this.storage) {
      return JSON.parse(this.storage.getItem(key));
    }
    return null;
  }

  clear(): boolean {
    if (this.storage) {
      this.storage.clear();
      return true;
    }
    return false;
  }

  isKeyNull(key) {
    if (this.storage.getItem(key) === null) {
      return true;
    }

    return false;
  }
}
