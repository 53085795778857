import { Injectable } from '@angular/core';
import { Router, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { SectionInformationService } from '../section-information/section-information.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService {
  infos;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) { }

  canActivate(): boolean {
    if (this.authService.isLoggedIn()) {
      return true;
    }

    this.router.navigate(['/login']);
    return false;
  }
}
