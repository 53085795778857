import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';

declare var $: any;

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class FavoritesComponent implements OnInit {
  showSpinner: boolean = false;
  companyId: any;
  clientId: any;
  isStockActive: boolean;
  showQtdField: boolean;
  isLoggedIn: boolean;
  wishlists: any[] = [];
  newWishlistName: string = '';
  newName: string = '';
  selectedWishlistId: number | null = null;
  deletedWishlistId: number | null = null;
  newFavorite: any[] = [];
  products = [];

  constructor (
    private localStorageService: LocalStorageService,
    private apiService: ApiService,
    private toastrService: ToastrService,
    private toastr: ToastrService,
  ) {
    this.companyId = this.localStorageService.get('biaction_id');
    this.clientId = this.localStorageService.get('pd_id');
  }


  ngOnInit(): void {
    this.getWishlist();
  }

  getWishlist(){
    this.apiService.getWishlists().subscribe((res) => {
      if (!res.error) {
        this.wishlists = res.wishlists;
        this.showSpinner = true;
      } else {
        console.error('Erro ao obter as listas de desejos:', res.message);
      }
    }, (err) => {
      console.error('Erro na solicitação ao servidor:', err);
    });
  }

  // ABRINDO O MODAL PARA EDITAR O NOME DA LISTA
  openEditModal(wishlistId: number) {
    this.selectedWishlistId = wishlistId;
    const selectedWishlist = this.wishlists.find(wishlist => wishlist.id === wishlistId);
    if (selectedWishlist) {
      this.newName = selectedWishlist.name;
    }
    $('#modal-edit-wishlist').modal('show');
  }

  // FUNÇÃO PARA EDITAR NOME DA LISTA
  editWishlist(wishlistId: number) {
    const name: string = this.newName;

    if (name.trim() === '') {
      alert('O nome da lista é obrigatório.');
      return;
    }

    this.apiService.updateWishlistName(name, wishlistId).subscribe((res) => {

      if (!res.error) {
        this.apiService.getWishlists().subscribe((res) => {
          if (!res.error) {
            this.wishlists = res.wishlists;
          }
          this.toastrService.showSuccess('Nome Atualizado com sucesso!');
        });
      } else {
        this.toastrService.showError('ja existe esse nome!');
      }
    }, (err) => {
      console.error('Erro na solicitação ao servidor:', err);
      this.toastrService.showSuccess('nome da lista manteve o mesmo !');
    });
  }


  // ABRINDO O MODAL PARA DELETAR UMA LISTA
  openDelModal(wishlistId: number){
    this.deletedWishlistId = wishlistId;
    this.wishlists.find(wishlist => wishlist.id === wishlistId);
    $('#modal-delete-wishlist').modal('show');
  }

  // FUNÇÃO PARA DELETAR UMA LISTA
  deleteWishlist() {
    this.apiService.deleteWishlist(this.deletedWishlistId).subscribe((res) => {
      if (!res.error) {
        this.apiService.getWishlists().subscribe((res) => {
          if (!res.error) {
            this.wishlists = res.wishlists;
          }
          this.toastrService.showSuccess('Lista deletada com sucesso!');
        });
      } else {
        alert(res.message || 'Erro ao deletar o nome da lista. Por favor, tente novamente.');
      }
    }, (err) => {
        console.error('Erro na solicitação ao servidor:', err);
        alert('Erro ao deletar a lista de desejos. Por favor, tente novamente.');
    });
  }





}

