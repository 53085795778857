import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api/api.service';
import { ToastrService } from '../../services/toastr/toastr-service.service';
import { UserService } from '../../services/user/user.service';
import { NgForm } from '@angular/forms';

declare var $: any;

@Component({
  selector: 'app-catalogs',
  templateUrl: './catalogs.component.html',
  styleUrls: ['./catalogs.component.css']
})
export class CatalogsComponent implements OnInit {

  loadedData:boolean = false;

  user;
  catalogs = [];
  catalog = {};

  constructor(
    private apiService: ApiService,
    private userService: UserService,
    private toastrService: ToastrService,
  ) { }

  ngOnInit() {
    this.getUser();
  }


  getUser() {
    this.userService.getUserData().then((res) => {
      if (!res['error']) {
        this.user = res['user'];
      }

      this.getCatalogs();
    });
  }


  getCatalogs() {
    this.apiService.getUserCatalogs(this.user.id).subscribe((res) => {
      if (!res['error']) {
        this.catalogs = res['catalogs'];
      }

      this.catalogs.forEach((catalog) => {
        catalog.link = this.makeLink(catalog);
      });

      this.loadedData = true;
    });
  }

  catalogDetails(catalog) {
    this.catalog = catalog;

    if (this.catalog['catalog']) {
      $('#catalog-details').modal('show');
    } else {
      this.toastrService.showError('Catálogo não encontrado!');
    }
  }

  save(catalog): void {
    this.apiService.updateCatalog(this.user.id, {
      catalog: catalog['catalog'],
      settings: catalog,
    }).subscribe((res) => {
      if (!res['error']) {
        this.toastrService.showSuccess(res['message']);
      } else {
        this.toastrService.showError(res['message']);
      }
    });
  }

  makeLink(catalog) {
    let link = '';

    if (catalog.slug !== null && catalog['slug'] !== '') {
      if (this.user.client.store_domain_type === 'domain') {
        link = `https://${this.user.client.store_domain}/catalogos/${catalog['catalog_id']}`;
      } else {
        link = `https://${this.user.client.store_subdomain}.sellentt.com.br/catalogos/${catalog['catalog_id']}`;
      }
    }

    return link;
  }

  redirect(catalog) {
    let links = this.makeLink(catalog);
    if (links !== '') {
      window.open(links, '_blank');
    } else {
      this.toastrService.showWarning('Catálogo não encontrado!');
    }
  }

  copyCatalogLink(catalog) {
    navigator.clipboard.writeText(this.makeLink(catalog)).then(() => {
      this.toastrService.showSuccess('Link do catálogo copiado com sucesso!');
    });
  }

}
