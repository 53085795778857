import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CartComponent } from './components/cart/cart.component';
import { CatalogsComponent } from './components/catalogs/catalogs.component';
import { CategoriesComponent } from './components/categories/categories.component';
import { HomeComponent } from './components/home/home.component';
import { LoginComponent } from './components/login/login.component';
import { OrderDetailsComponent } from './components/order-details/order-details.component';
import { OrderReviewComponent } from './components/order-review/order-review.component';
import { OrdersComponent } from './components/orders/orders.component';
import { PrecartComponent } from './components/precart/precart.component';
import { ProductDetailsComponent } from './components/product-details/product-details.component';
import { RegisterComponent } from './components/register/register.component';
import { StoresComponent } from './components/stores/stores.component';
import { SuggestedListsComponent } from './components/suggested-lists/suggested-lists.component';
import { UserProfileComponent } from './components/user-profile/user-profile.component';
import { AuthGuardService } from './services/auth-guard/auth-guard.service';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { PasswordResetComponent } from './components/password-reset/password-reset.component';
import {OrderConfirmationComponent} from "./components/order-confirmation/order-confirmation.component";
import { FavoritesComponent } from './components/favorites/favorites.component';
import {FavoritesDetailsComponent } from './components/favorites-details/favorites-details.component';
import { MaintenanceComponent } from './components/maintenance/maintenance.component';
import { MaintenanceGuard } from './services/maintenance-guard/maintenance.guard';
import { CatalogLinkComponent } from './components/catalog-link/catalog-link.component';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', canActivate: [MaintenanceGuard], component: LoginComponent },
  { path: 'register', canActivate: [MaintenanceGuard], component: RegisterComponent },
  { path: 'forgot-password', canActivate: [MaintenanceGuard], component: ForgotPasswordComponent},
  { path: 'password-reset', canActivate: [MaintenanceGuard], component: PasswordResetComponent},
  { path: 'home', canActivate: [MaintenanceGuard], component: HomeComponent },

  { path: 'home/:term', canActivate: [AuthGuardService, MaintenanceGuard], component: HomeComponent },
  { path: 'cart', canActivate: [AuthGuardService, MaintenanceGuard], component: CartComponent },
  { path: 'precart', canActivate: [AuthGuardService, MaintenanceGuard], component: PrecartComponent },
  { path: 'precart/:id', canActivate: [AuthGuardService, MaintenanceGuard], component: PrecartComponent },
  { path: 'categories/:id', canActivate: [AuthGuardService, MaintenanceGuard], component: CategoriesComponent },
  { path: 'products/:id', component: ProductDetailsComponent },
  { path: 'user-profile', canActivate: [AuthGuardService, MaintenanceGuard], component: UserProfileComponent },
  { path: 'orders', canActivate: [AuthGuardService, MaintenanceGuard], component: OrdersComponent },
  { path: 'orders/:code', canActivate: [AuthGuardService, MaintenanceGuard], component: OrderDetailsComponent },
  { path: 'orders/:code/review', canActivate: [AuthGuardService, MaintenanceGuard], component: OrderReviewComponent },
  { path: 'orders/:code/confirmation', canActivate: [AuthGuardService, MaintenanceGuard], component: OrderConfirmationComponent },
  { path: 'stores', canActivate: [AuthGuardService, MaintenanceGuard], component: StoresComponent },
  { path: 'catalogs', canActivate: [MaintenanceGuard], component: CatalogsComponent },
  { path: 'catalogs/:id', canActivate: [MaintenanceGuard], component: CatalogLinkComponent },
  { path: 'favorites', canActivate: [AuthGuardService, MaintenanceGuard], component: FavoritesComponent },
  { path: 'favorites-details/:id', component: FavoritesDetailsComponent },
  { path: 'home/:term', canActivate: [AuthGuardService, MaintenanceGuard], component: HomeComponent },
  { path: 'cart', canActivate: [AuthGuardService, MaintenanceGuard], component: CartComponent },
  { path: 'precart', canActivate: [AuthGuardService, MaintenanceGuard], component: PrecartComponent },
  { path: 'precart/:id', canActivate: [AuthGuardService, MaintenanceGuard], component: PrecartComponent },
  { path: 'categories/:id', canActivate: [AuthGuardService, MaintenanceGuard], component: CategoriesComponent },
  { path: 'products/:id', canActivate: [MaintenanceGuard], component: ProductDetailsComponent },
  { path: 'user-profile', canActivate: [AuthGuardService, MaintenanceGuard], component: UserProfileComponent },
  { path: 'orders', canActivate: [AuthGuardService, MaintenanceGuard], component: OrdersComponent },
  { path: 'orders/:code', canActivate: [AuthGuardService, MaintenanceGuard], component: OrderDetailsComponent },
  { path: 'orders/:code/review', canActivate: [AuthGuardService, MaintenanceGuard], component: OrderReviewComponent },
  { path: 'orders/:code/confirmation', canActivate: [AuthGuardService, MaintenanceGuard], component: OrderConfirmationComponent },
  { path: 'stores', canActivate: [AuthGuardService, MaintenanceGuard], component: StoresComponent },
  { path: 'favorites', canActivate: [AuthGuardService, MaintenanceGuard], component: FavoritesComponent },
  { path: 'suggested_lists', canActivate: [AuthGuardService, MaintenanceGuard], component: SuggestedListsComponent },
  { path: 'maintenance', component: MaintenanceComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
